bcl.c.mybMenu = {
  props: {
    className: "c-myb-menu",
    textMessage: ".js-text-message",
    navContainer: ".js-myb-menu-nav",
    itemNav: ".js-nav-item",
  },

  levels: [0, 1, 3],

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    const $component = baseFind.getElementsByClassName(bcl.c.mybMenu.props.className);
    if (!$component || !$component[0]) {
      return;
    }
    bcl.c.mybMenu.props.$container = $component;
    bcl.c.mybMenu.setHeaderValue();
    Array.from($component).forEach((element) => {
      bcl.c.mybMenu.updateInfo(element);
      bcl.c.mybMenu.linksClick(element);
      //bcl.c.mybMenu.checkAnchorTabMenu();
      bcl.c.mybMenu.checkAnchor();
    });
  },

  setHeaderValue: function () {
    if (document.body.dataset.mode === "true") {
      const text = document.querySelector(".js-name-myb");
      const name = bcl.profile.mapValues.data && bcl.profile.mapValues.data.name ? bcl.profile.mapValues.data.name : "";
      if (name) {
        text.innerHTML = text.innerHTML.replace("[client]", name);
      }
    }
  },

  updateInfo: function (component) {
    const texts = component.querySelectorAll(bcl.c.mybMenu.props.textMessage);
    let indexAux;
    bcl.c.mybMenu.levels.forEach((element, index) => {
      if (bcl.profile.mapValues.data.bookingNumber >= element) {
        indexAux = index;
      }
    });
    let isMax = bcl.profile.mapValues.data.bookingNumber >= 5;
    if (component.classList.contains("mod--menu-v2")) {
      indexAux = bcl.profile.mapValues.data.level - 1;
      isMax = bcl.profile.mapValues.data.level - 1 > 3;
    }
    if (indexAux >= 0 && texts[indexAux] && !isMax) {
      texts[indexAux].classList.remove("mod--hidden");
      const numberTotal = bcl.c.mybMenu.levels[indexAux + 1] ? parseInt(bcl.c.mybMenu.levels[indexAux + 1]) : 5;
      const numberDifferent = numberTotal - parseInt(bcl.profile.mapValues.data.bookingNumber);
      for (const child of texts[indexAux].children) {
        if (child.innerHTML.includes("[name]") && bcl.profile.mapValues.data.name) {
          child.innerHTML = child.innerHTML.replace("[name]", bcl.profile.mapValues.data.name);
        }
        if (child.innerHTML.includes("[mybLeftStays]") && numberDifferent) {
          child.innerHTML = child.innerHTML.replace("[mybLeftStays]", numberDifferent.toString());
        }
      }
    }
  },

  linksClick: function (component) {
    const navContainer = component.querySelector(bcl.c.mybMenu.props.navContainer);
    const items = navContainer.querySelectorAll(bcl.c.mybMenu.props.itemNav);
    items.forEach((element) => {
      element.addEventListener("click", () => {
        this.checkAnchor(element.href);
        //this.checkAnchorTabMenu(element.href);
      });
    });
  },

  checkAnchor: function (href) {
    href = href ? href : window.location.href;
    const key = href.split("#")[1] ? href.split("#")[1] : "";
    if (key) {
      const faq = document.querySelector(".c-faq");

      if (!faq) {
        return;
      }

      const childrensNodes = faq.querySelectorAll(".menu-faq-js");
      const childrensContent = faq.querySelectorAll(".block-quest-faq-js");
      childrensNodes.forEach((elementChild) => {
        if (elementChild.dataset.anchor === key) {
          if (!elementChild.classList.contains("c-faq__nav-item-active")) {
            elementChild.classList.add("c-faq__nav-item-active");
          }
          this.updateFaqContent(childrensContent, elementChild.dataset.attribute);
        } else {
          if (elementChild.classList.contains("c-faq__nav-item-active")) {
            elementChild.classList.remove("c-faq__nav-item-active");
          }
        }
      });
    }
  },

  checkAnchorTabMenu: function (href) {
    href = href ? href : window.location.href;
    const key = href.split("#")[1] ? href.split("#")[1] : "";
    if (key) {
      const faq = document.querySelector(".c-tab-menu");
      if (faq) {
        const childrensNodes = faq.querySelectorAll(".menu-tab-menu-js");
        const childrensContent = faq.querySelectorAll(".block-quest-tab-menu-js");
        childrensNodes.forEach((elementChild) => {
          if (elementChild.dataset.anchor === key) {
            if (!elementChild.classList.contains("c-tab-menu__nav-item-active")) {
              elementChild.classList.add("c-tab-menu__nav-item-active");
            }
            this.updateFaqContent(childrensContent, elementChild.dataset.attribute);
          } else {
            if (elementChild.classList.contains("c-tab-menu__nav-item-active")) {
              elementChild.classList.remove("c-tab-menu__nav-item-active");
            }
          }
        });
      }
    }
  },

  updateFaqContent: function (childrensContent, value) {
    childrensContent.forEach((element) => {
      if (element.dataset.attribute === value) {
        if (element.classList.contains("hidden")) {
          element.classList.remove("hidden");
        }
        bcl.u.goToWithScroll({
          top: bcl.s.dataAnchor.getScrollTop(element) + bcl.s.dataAnchor.props.offset,
        });
      } else {
        if (!element.classList.contains("hidden")) {
          element.classList.add("hidden");
        }
      }
    });
  },
};
